import classNames from "classnames";
import styled, { css } from "styled-components";

const Wrapper = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "projects__project-banner-wrapper")
  }
})`
  ${() => css`
    flex: 1;
    overflow: hidden;
    pointer-events: none;
  `}
`;

Wrapper.defaultProps = {
  Tag: "div"
};

export default Wrapper;
