import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const ArrowWrapper = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "projects__project-header-contents-arrow-wrapper"
    )
  };
})`
  ${() => `
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}

  ${media.sm`
    ${ ({ theme: { space } }) => `
      && {
        justify-content: flex-end;
        padding: 0 0 ${ space.xxSmall };
      }
    `}
  `}
`;

ArrowWrapper.propTypes = {};

ArrowWrapper.defaultProps = {};

export default ArrowWrapper;
