import classNames from "classnames";
import PropTypes from "prop-types";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const Header = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "projects__project-header")
  };
})`
  ${ ({ theme: { borders, colors, space }, transitionMs }) => `
    display: flex;
    flex-direction: row;
    height: 40px;
    justify-content: flex-start;
    // If Nowadays ever wants exclusion turned back on, find all instances of
    // this comment and uncomment the mix-blend-mode line.
    // mix-blend-mode: exclusion;
    padding: 0 ${ space.small };
    position: static;
    width: 100%;
    z-index: 1;

    &:after {
      // Combination gradient background-image and box-shadow to support
      // cross-browser subpixel borders. Be careful reconfiguring these values.
      background-image:
        linear-gradient(
          to top, ${ colors.white } 0%, ${ colors.white } 51%, transparent 51%
        );
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: 100% 1px;
      box-shadow: ${ borders.bottom } ${ colors.white };
      bottom: 0;
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      right: 0;
    }
  `}

  ${media.sm`
    ${({ theme: { colors, space }, transitionMs }) => `
      && {
        flex-direction: column;
        height: 100%;
        justify-content: flex-end;
        left: 0;
        padding:
          ${ space.large }
          ${ space.xLarge }
          ${ space.large}
          ${ space.large };
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 66.6666666666666666%;

        &:after {
          opacity: 0;
          right: ${ space.xxSmall };
          transition:
            opacity ${ transitionMs }ms cubic-bezier(.455, .03, .515, 1.0),
            transform ${ transitionMs }ms cubic-bezier(.455, .03, .515, 1.0);
          transform: scaleX(0.0);
          transform-origin: left;
          will-change: opacity, transform;
          -webkit-backface-visibility: hidden;
          -webkit-transform-style: preserve-3d;
        }
      }
    `}
  `}

  ${media.md`
    ${({ theme: { space }, transitionMs }) => `
      && {
        flex-direction: column;
        height: 100%;
        justify-content: flex-end;
        left: 0;
        padding:
          ${ space.large }
          ${ space.xLarge }
          ${ space.large}
          ${ space.large };
        position: absolute;
        top: 0;
        width: 50%;
      }
    `}
  `}

  ${media.lg`
    ${({ theme: { space }, transitionMs }) => `
      && {
        flex-direction: column;
        height: 100%;
        justify-content: flex-end;
        left: 0;
        padding:
          ${ space.large }
          ${ space.xLarge }
          ${ space.large}
          ${ space.large };
        position: absolute;
        top: 0;
        width: 33.3333333333333333%;
      }
    `}
  `}
`;

Header.propTypes = {
  transitionMs: PropTypes.number.isRequired
};

Header.defaultProps = {};

export default Header;
