import React from "react";

import { Metadata } from "@nowadays/Core";
import { Container } from "@nowadays/UI/Grid";

import Project from "./components/Project";

const Projects = props => {
  const { data: { projects, projectsPage: { metadata } } } = props;

  return (
    <React.Fragment>
      <Metadata metadata={metadata}/>
      <Container>
        {
          projects.edges.map(({ node: project }, i) => (
            <Project key={`projects__project-k-${ i }`} project={project}/>
          ))
        }
      </Container>
    </React.Fragment>
  );
};

export default Projects;
