import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

import { Text } from "@nowadays/UI/Typography";

const Title = styled(Text).attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "projects__project-header__title")
  };
})`
  ${({ theme: { fontSizes, lineHeights } }) => `
    font-size: ${ fontSizes.large };
    line-height: ${ lineHeights.large};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}

  ${media.sm`
    ${({ theme: { fontSizes, lineHeights } }) => `
      font-size: ${ fontSizes.xxLarge };
      line-height: ${ lineHeights.xxLarge};
      overflow: visible;
      text-overflow: clip;
      white-space: normal;
    `}
  `}
`;

Title.propTypes = {};

Title.defaultProps = {
  expanded: true
};

export default Title;
