import React from "react";
import PropTypes from "prop-types";

import StyledArrow from "./views/Arrow";
import ArrowWrapper from "./views/ArrowWrapper";

const Arrow = props => {
  const { transitionMs } = props;

  return (
    <ArrowWrapper>
      <StyledArrow transitionMs={transitionMs}/>
    </ArrowWrapper>
  );
};

Arrow.propTypes = {
  transitionMs: PropTypes.number.isRequired
};

export default Arrow;
