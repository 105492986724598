import React from "react";
import { graphql } from "gatsby";

import ProjectsPageComponent from "@nowadays/Pages/Projects";

const Projects = props => {
  const { data } = props;

  return (
    <ProjectsPageComponent data={data}/>
  );
};

export const query = graphql`
  query Projects {
    projects: allSanityProject(
      sort: {
        fields: [releaseDate]
        order: DESC
      }
    ) {
      edges {
        node {
          ...fieldsOnProject
        }
      }
    }
    projectsPage: sanityProjectsPage {
      ...fieldsOnProjectsPage
    }
  }
`;

export default Projects;
