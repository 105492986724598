import classNames from "classnames";
import PropTypes from "prop-types";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

import { RightArrow } from "@nowadays/UI/Icon";

const Arrow = styled(RightArrow).attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "projects__project-header-contents-arrow")
  };
})`
  ${ ({ theme: { colors, space } }) => `
    fill: ${ colors.white };
    height: ${ space.xSmall };
  `}

  ${media.sm`
    ${ ({ theme: { space }, transitionMs }) => `
      opacity: 0;
      // Turn up transition out speed. This is a bit of a hack but useful for
      // making sure the arrow just gets right out of view when active moves
      // to false.
      transition:
        opacity ${ transitionMs / 8 }ms cubic-bezier(.455, .03, .515, 1.0),
        transform ${ transitionMs / 8 }ms cubic-bezier(.455, .03, .515, 1.0);
      transform: translateX(-${ space.xxSmall });
      will-change: opacity, transform;
      -webkit-backface-visibility: hidden;
      -webkit-transform-style: preserve-3d;
    `}
  `}
`;

Arrow.propTypes = {
  transitionMs: PropTypes.number.isRequired
};

Arrow.defaultProps = {
  active: false
};

export default Arrow;
