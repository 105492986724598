import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";

const ProjectLink = styled(
  ({ focused, ...rest }) => <Link {...rest} />
).attrs(props => {
  const {
    className,
    focused
  } = props;

  return {
    className: classNames(className, "projects__project-link", {
      "projects__project-link--is-focused": focused
    })
  };
})`
  ${({ theme: { colors, space } }) => `
    color: ${ colors.copy };
    cursor: pointer;
    display: block;
    height: 450px;
    margin: ${ space.xxSmall } 0;
    outline: none;
    text-align: left;
    text-decoration: none;
    width: 100%;

    // :focus managed by KeyboardFocusContainer
    &.projects__project-link--is-focused
    {
      outline: auto;
    }
  `};
`;

ProjectLink.propTypes = {
  focused: PropTypes.bool
};

ProjectLink.defaultProps = {
  focused: false
};

export default ProjectLink;
