import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const Project = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "projects__project")
  };
})`
  ${() => `
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    outline: none;
    position: relative;
    transform: translate3d(0, 0, 0);
  `};

  ${media.sm`
    ${() => `
      flex-direction: row;
      justify-content: flex-end;
    `}
  `}
`;

Project.propTypes = {};

Project.defaultProps = {};

export default Project;
