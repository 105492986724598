import React from "react";
import { KeyboardFocusContainer } from "@zendeskgarden/container-keyboardfocus";

import Banner from "./components/Banner";
import Header from "./components/Header";
import HeaderBackdrop from "./views/HeaderBackdrop";
import StyledProject from "./views/Project";
import ProjectLink from "./views/ProjectLink";

const Project = props => {
  const { project } = props;

  return (
    <KeyboardFocusContainer>
      {({ getFocusProps, keyboardFocused }) => (
        <ProjectLink
          {...getFocusProps({
            focused: keyboardFocused
          })}
          to={`/projects/${project.slug.current}`}
        >
          <StyledProject>
            <Banner
              project={project}
            />
            <HeaderBackdrop
              transitionMs={Project.TRANSITION_MS}
            />
            <Header
              transitionMs={Project.TRANSITION_MS}
              project={project}
            />
          </StyledProject>
        </ProjectLink>
      )}
    </KeyboardFocusContainer>
  );
};

Project.TRANSITION_MS = 256;

export default Project;

