import React from "react";
import PropTypes from "prop-types";

import Arrow from "./components/Arrow";
import Client from "./views/Client";
import Contents from "./views/Contents";
import StyledHeader from "./views/Header";
import TextsWrapper from "./views/TextsWrapper";
import Title from "./views/Title";

const Header = props => {
  const {
    project: { client, title },
    transitionMs
  } = props;

  return (
    <StyledHeader
      transitionMs={transitionMs}
    >
      <Contents>
        <TextsWrapper>
          <Client>
            { client }
          </Client>
          <Title>
            { title }
          </Title>
        </TextsWrapper>
        <Arrow
          transitionMs={transitionMs}
        />
      </Contents>
    </StyledHeader>
  );
};

Header.propTypes = {
  project: PropTypes.object.isRequired,
  transitionMs: PropTypes.number.isRequired
};

export default Header;
