import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const TextsWrapper = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "projects__project-header__texts-wrapper")
  };
})`
  ${({ theme: { space } }) => `
    align-items: center;
    display: flex;
    height: 40px;
    margin-bottom: -3px;
    min-width: 0;
    padding-bottom: 3px;
    padding-right: ${ space.xxSmall };
    z-index: 1;
  `}

  ${media.sm`
    ${() => `
      align-items: initial;
      display: -webkit-box;
      flex: 1;
      height: unset;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      -webkit-line-clamp: 5; /* number of lines to show */
      -webkit-box-orient: vertical;
    `}
  `}
`;

TextsWrapper.propTypes = {};

TextsWrapper.defaultProps = {};

export default TextsWrapper;
