import classNames from "classnames";
import PropTypes from "prop-types";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

import Arrow from "../components/Header/components/Arrow/views/Arrow";
import Header from "../components/Header/views/Header";

const HeaderBackdrop = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "projects__project-header-backdrop")
  };
})`
  ${ ({ theme: { borders, colors, space }, transitionMs }) => `
    display: none;
  `}

  ${media.sm`
    ${({ theme: { colors, space }, transitionMs }) => `
      && {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-end;
        left: 0;
        padding:
          ${ space.large }
          ${ space.xLarge }
          ${ space.large}
          ${ space.large };
        position: absolute;
        top: 0;
        width: 66.6666666666666666%;

        &:before {
          background: ${ colors.black };
          bottom: 0;
          content: "";
          left: 0;
          opacity: 0;
          position: absolute;
          right: 0;
          top: 0;
          transition:
            opacity ${ transitionMs }ms cubic-bezier(.455, .03, .515, 1.0),
            transform ${ transitionMs }ms cubic-bezier(.455, .03, .515, 1.0);
          transform: scaleX(0.0);
          transform-origin: left;
          will-change: opacity, transform;
          -webkit-backface-visibility: hidden;
          -webkit-transform-style: preserve-3d;
        }

        &:hover {
          &:before {
            opacity: 1;
            transform: scaleX(1.0);
          }

          + ${ Header } {
            &:after {
              opacity: 1;
              transform: scaleX(1.0);
            }

            ${ Arrow } {
              opacity: 1;
              transform: translateX(0);
              transition:
                opacity ${ transitionMs }ms cubic-bezier(.455, .03, .515, 1.0),
                transform ${ transitionMs }ms cubic-bezier(.455, .03, .515, 1.0);
              transition-delay: ${ transitionMs }ms;
            }
          }
        }
      }
    `}
  `}

  ${media.md`
    ${({ theme: { space } }) => `
      && {
        flex-direction: column;
        height: 100%;
        justify-content: flex-end;
        left: 0;
        padding:
          ${ space.large }
          ${ space.xLarge }
          ${ space.large}
          ${ space.large };
        position: absolute;
        top: 0;
        width: 50%;
      }
    `}
  `}

  ${media.lg`
    ${({ theme: { space } }) => `
      && {
        flex-direction: column;
        height: 100%;
        justify-content: flex-end;
        left: 0;
        padding:
          ${ space.large }
          ${ space.xLarge }
          ${ space.large}
          ${ space.large };
        position: absolute;
        top: 0;
        width: 33.3333333333333333%;
      }
    `}
  `}
`;

HeaderBackdrop.propTypes = {
  transitionMs: PropTypes.number.isRequired
};

HeaderBackdrop.defaultProps = {};

export default HeaderBackdrop;
