import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const Contents = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "projects__project-header__contents")
  };
})`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${media.sm`
    ${() => `
      justify-content: flex-start;
      // If Nowadays ever wants exclusion turned back on, find all instances of
      // this comment and uncomment the mix-blend-mode line.
      // mix-blend-mode: exclusion;
    `}
  `}
`;

Contents.propTypes = {};

Contents.defaultProps = {};

export default Contents;
