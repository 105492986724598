import React from "react";
import Img from "gatsby-image";

import Wrapper from "./views/Wrapper";

const Banner = props => {
  const { project: { banner, bannerAltText } } = props;

  const hotspot = banner.hotspot || {};

  const {
    x=0.5,
    y=0.5
  } = hotspot;

  const fluid = banner.asset.fluid;

  return (
    <Wrapper>
      <Img
        alt={bannerAltText}
        fadeIn={false}
        fluid={fluid}
        loading="eager"
        objectFit="cover"
        objectPosition={`${ x * 100 }% ${ y * 100 }%`}
        style={
          {
            height: "100%",
            position: "relative",
            width: "100%",
            willChange: "transform"
          }
        }
        placeholderStyle={
          {
            filter: "blur(20px)"
          }
        }
      />
    </Wrapper>
  );
};

export default Banner;

